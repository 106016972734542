import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import Loader from '../components/block-components/loader/loader';
import { routeConstants } from '../services/constants/route-constants';
import ProctedRoutes from './protected-routes';


const AdminRoutes = lazy(() => import("./admin-route"));
const ArcPManagerRoutes = lazy(() => import("./arcp-manager-route"));
const FacilitatorRoutes = lazy(() => import("./facilitator-route"));
const StateCoordinatorRoutes = lazy(() => import("./state-coordinator-route"));
const HomeRoutes = lazy(() => import("./home-route"));

function BaseRoute() {
  return (
    <Router>
      <Suspense fallback={<Loader/>}>
        <Routes>
          <Route element={<ProctedRoutes/>}>
            <Route path={`${routeConstants.systemAdmin}/*`} element={<AdminRoutes/>}></Route>
            <Route path={`${routeConstants.arcpManager}/*`} element={<ArcPManagerRoutes/>}></Route>
            <Route path={`${routeConstants.facilitator}/*`} element={<FacilitatorRoutes/>}></Route>
            <Route path={`${routeConstants.stateCoordinator}/*`} element={<StateCoordinatorRoutes/>}></Route>
            <Route path={`${routeConstants.stateOfficial}/*`} element={<StateCoordinatorRoutes/>}></Route>
          </Route>
          <Route path={'*'} element={<HomeRoutes/>}></Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default BaseRoute;
