import { SETVERSION, CLEARVERSION } from "../constants/action-constants"


export const settingsReducer = (state: any = {}, action: any) => {
    switch(action.type) {
        case SETVERSION:
            return {...state, appVersion: action.payload};
        case CLEARVERSION:
            return {...state, appVersion: undefined};
        default:
            return state;
    }
}