 
 
 export const routeConstants = {
     all: '*',
     home: '/',
     about: 'about',
     login: 'log-in',
     register: 'register',
     terms: 'terms',
     faq: 'faq',
     unauthorized: 'unauthorized',
     forgetPassword: '/forget-password',
     resetPassword: 'reset-password',

     admin: 'admin',
     systemAdmin: 'system-admin',
     clientManager: 'client-manager',
     pillarManager: 'pillar-manager',
     facilitator: 'facilitator',
     arcpManager: 'arcp-manager',
     stateCoordinator: 'state-coordinator',
     stateOfficial: 'state-Official',
     downloadIDCards: "facilitators/download-id",

     facilitatorDashboard: 'facilitator-dashboard',
     facilitatorList: 'facilitators',
     facilitatorNominations: 'facilitator-nominations',
     childrenDashboard: 'children-dashboard',
     childrenList: 'children',
     guardianNominations: 'guardian-nominations',
     childrenNominations: 'children-nominations',
     facilitatorReports: 'facilitator-reports',
     pillarReports: 'pillar-reports',
     generalReport: 'general-report',
     pillarReport: 'pillar-report',
     reportsDashboard: 'reports-dashboard',

     facilitatorSignup: 'facilitator-signup',

     hubDashboard: 'hub-dashboard',
     cohortList: 'cohort-list',
     pillars: 'pillars',
     hubs: 'hubs',
     events: 'events',
     scheduledEvents: 'scheduled-events',

     userManagement: 'user-management',
     stateOfficialManagement: 'state-official-management',
     profile: 'profile',
     appSettings: 'app-settings',

     userLevels: {
        systemAdmin: 'SYSTEMADMIN',
        clientManager: 'CLIENTMANAGER',
        pillarManager: 'PILLARMANAGER',
        facilitator: 'FACILITATOR',
        arcpManager: 'ARCPMANAGER',
        stateCoordinator: 'STATECOORDINATOR',
        stateOfficial: 'STATEOFFICIAL',
     },
 };
